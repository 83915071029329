import React, { useState } from "react";

import Layout from "components/Global/Layout";

import Yesterade from "../assets/images/yesterade.svg";
import madeLeft from "../assets/images/made-left.png";
import madeRight from "../assets/images/made-right.png";
import ball from "../assets/images/ball.png";

const AboutPage = () => {
	const [isHoveringLinkedin, setIsHoveringLinkedin] = useState(false);

	return (
		<Layout
			pageName="about"
			amazon="https://www.amazon.com/dp/B0CKRXJL6Q?maas=maas_adg_815CA62E3984F0A5167041CC3D3DDA58_afap_abs&ref_=aa_maas&tag=maas"
		>
			<div className="about">
				<h2>About Us</h2>

				<div className="about__content">
					<p>
						We were born one day not so long ago when our Founder's three sporty
						children broke their previous single Saturday record by drinking
						eight bright red, pollutively plastic-packaged "Sports Drink" sugar
						bombs.
					</p>
					<p>
						That Sunday he began assembling a crack team of industry badasses to
						start a company that offered way healthier products, along with
						super-fun, occasionally educational and always insane stories about
						the people who used them to build, fight, invent and compete when
						life was mythically hard (and there weren't even Summer Fridays).
					</p>
					<p>
						Life was a (very, very dangerous) sport back then, these drinks
						fueled the competition, and we're excited to reintroduce them to a
						new generation who will be overpowered by the past and more
						supercharged for the future.
					</p>

					<img className="about__ball" src={ball} alt="ball" />
				</div>

				<div className="about__newsletter">
					<div className="about__newsletter-content">
						<h3>
							<span>Ancient Drinks</span>{" "}
							<img src={Yesterade} alt="Yesterade to Slay Tomorrow" />
						</h3>

						<p>
							If you have any questions about the brand or our products, if you
							have a press or employment inquiry, or would like to stock Ancient
							Drinks, please contact us at{" "}
							<a href="mailto:hello@ancientdrinks.com">
								hello@ancientdrinks.com
							</a>
						</p>
					</div>
				</div>

				<div className="about__newsletter-background">
					<div className="about__newsletter-inner">
						<div>
							<img src={madeLeft} />
						</div>
						<div>
							<img src={madeRight} />
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default AboutPage;
